define("sharedrop/models/user", ["exports", "sharedrop/models/peer"], function (_exports, _peer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const User = _peer.default.extend({
    serialize() {
      const data = {
        uuid: this.uuid,
        public_ip: this.public_ip,
        label: this.label,
        avatarUrl: this.avatarUrl,
        peer: {
          id: this.get('peer.id')
        }
      };
      return data;
    }
  });
  var _default = User;
  _exports.default = _default;
});