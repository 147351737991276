define("sharedrop/templates/about-room", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lx7Vwk12",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],null,[[\"onClose\"],[[30,[36,4],[[32,0],\"closeModal\"],[[\"target\"],[[35,5]]]]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"h2\"],[14,0,\"logo\"],[12],[10,\"span\"],[12],[2,\"ShareDrop\"],[13],[13],[2,\"\\n  \"],[10,\"h3\"],[12],[2,\"Share files between devices in different networks\"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[12],[2,\"\\n    Copy provided address as well as your current name \"],[10,\"b\"],[12],[1,[35,0,[\"label\"]]],[13],[2,\" and send it to the other person...\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"value\",\"readonly\",\"style\"],[[35,1],\"readonly\",\"display: block; margin: auto;\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[12],[2,\"\\n    Or you can scan it on the other device.\\n  \"],[13],[2,\"\\n\\n\\n  \"],[10,\"p\"],[14,0,\"qr-code\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"text\"],[[35,1]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[12],[2,\"\\n    Once the other person open this page in a browser, you'll see each other's avatars.\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[12],[2,\"\\n    Drag and drop a file directly on other person's avatar or click the avatar and select the file you want to send. The file transfer will start once the recipient accepts the file.\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,4,\"button\"],[4,[38,4],[[32,0],\"closeModal\"],null],[12],[2,\"Got it!\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"you\",\"currentUrl\",\"room-url\",\"qr-code\",\"action\",\"currentRoute\",\"modal-dialog\"]}",
    "meta": {
      "moduleName": "sharedrop/templates/about-room.hbs"
    }
  });
  _exports.default = _default;
});