define("sharedrop/routes/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    renderTemplate(controller, error) {
      const errors = ['browser-unsupported', 'filesystem-unavailable'];
      const name = `errors/${error.message}`;
      if (errors.indexOf(error.message) !== -1) {
        this.render(name);
      }
    }
  });
  _exports.default = _default;
});