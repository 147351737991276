define("sharedrop/helpers/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(_ref => {
    let [leftSide, rightSide] = _ref;
    return leftSide === rightSide;
  });
  _exports.default = _default;
});