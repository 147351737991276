define("sharedrop/components/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      close() {
        // This sends an action to application route.
        // eslint-disable-next-line ember/closure-actions
        return this.onClose();
      }
    }
  });
  _exports.default = _default;
});