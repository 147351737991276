define("sharedrop/templates/components/popover-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+sbXHwlo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"popover\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"popover-body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"popover-icon\"],[12],[2,\"\\n      \"],[10,\"i\"],[15,0,[34,3]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[18,1,null],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"popover-buttons\"],[12],[2,\"\\n\"],[6,[37,4],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"cancel\"],null],[12],[1,[34,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"confirm\"],null],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"confirmButtonLabel\",\"cancelButtonLabel\",\"iconClass\",\"if\"]}",
    "meta": {
      "moduleName": "sharedrop/templates/components/popover-confirm.hbs"
    }
  });
  _exports.default = _default;
});