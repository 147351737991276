define("sharedrop/templates/about-you", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fFYP6hd5",
    "block": "{\"symbols\":[],\"statements\":[[2,\"对方将通过 \"],[10,\"b\"],[12],[1,[35,0,[\"label\"]]],[13],[2,\" 找到你。Other people will see you as \"],[10,\"b\"],[12],[1,[35,0,[\"label\"]]],[13],[2,\".\\n\"]],\"hasEval\":false,\"upvars\":[\"you\"]}",
    "meta": {
      "moduleName": "sharedrop/templates/about-you.hbs"
    }
  });
  _exports.default = _default;
});